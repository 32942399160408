import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FeatureImage,
  FeatureImageHero,
  FeatureImageHeroMobile,
  H2,
  Paragraph,
  TwoColumnContainer,
  Container,
  ThreeColumnGrid,
} from "../components/styled/components"
import Fade from "react-reveal/Fade"
import { useMediaQuery } from "react-responsive"
import contact_tracing_icon from "../images/contact_tracing.svg"
import iv_icon from "../images/IV_Icon.svg"
import vaccine_icon from "../images/Vaccine_Icon.svg"
import testing_icon from "../images/Testing_Icon.svg"
import occupational_icon from "../images/Occupational_Icon.svg"
import lab_icon from "../images/Lab_Icon.svg"
import telemedicine_icon from "../images/Telemedicine_Icon.svg"

const ServicesPage = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <Layout>
      <SEO title="Services" />

      {isTabletOrMobile ?
      <FeatureImageHeroMobile>
        <H2 fontSize="40px" textAlign="center">
          {data.contentfulPage.heroHeader}
        </H2>
        <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
      </FeatureImageHeroMobile> :
      <FeatureImageHero>
        <section>
          <h2>
            {data.contentfulPage.heroHeader}
          </h2>
          <Paragraph>
            {data.contentfulPage.heroDescription.heroDescription}
          </Paragraph>
        </section>
        <FeatureImage>
          <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
        </FeatureImage>
      </FeatureImageHero>}
      
      <Container
        textAlign="center"
        css={{ marginBottom: "100px", paddingTop: "40px" }}
      >
        <Fade bottom>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "9fr 1fr 12fr", gridColumnGap: 0 }}>
            <div>
              <H2 fontSize="42px">COVID-19</H2>
              <Link to="/covid-19/">View more details</Link>
              <ThreeColumnGrid style={{ gridColumnGap: "50px" }}>
                <div>
                  <img src={testing_icon} alt="COVID-19 Testing" />
                  <div className="service-name">
                    <span>COVID-19 Testing</span>
                  </div>
                </div>
                <div>
                  <img src={contact_tracing_icon} alt="Contact Tracing" />
                  <div className="service-name">
                    <span>Contact Tracing</span>
                  </div>
                </div>
                <div>
                  <img src={vaccine_icon} alt="Vaccine Services" />
                  <div className="service-name">
                    <span>Vaccine Services</span>
                  </div>
                </div>
              </ThreeColumnGrid>
            </div>
            <div style={{ margin: "auto", borderLeft: "2px solid #122A47", height: "100%", width: 0 }}></div>
            <div>
              <H2 fontSize="42px">Health Services</H2>
              <ThreeColumnGrid style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr", gridColumnGap: "50px" }}>
                <div>
                  <img src={occupational_icon} alt="Occupational Health" />
                  <div className="service-name">
                    <span>Well to Life Occupational Health</span>
                  </div>
                </div>
                <div>
                  <img src={lab_icon} alt="Laboratory Services" />
                  <div className="service-name">
                    <span>Laboratory Services</span>
                  </div>
                </div>
                <div>
                  <img src={iv_icon} alt="IV Infusion" />
                  <div className="service-name">
                    <span>IV Infusion</span>
                  </div>
                </div>
                <div>
                  <img src={telemedicine_icon} alt="Telemedicine" />
                  <div className="service-name">
                    <span>Telemedicine</span>
                  </div>
                </div>
              </ThreeColumnGrid>
            </div>
          </ThreeColumnGrid>
        </Fade>
      </Container>

      {data.contentfulPage.section1 && 
      <TwoColumnContainer style={{ width: "80%" }}>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section1.sectionHeader}</H2>
            {data.contentfulPage.section1.sectionLink &&
            <Link to={data.contentfulPage.section1.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section1.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img style={{ margin: "auto", maxWidth: "200px" }} fluid={data.contentfulPage.section1.sectionImage.fluid} alt={data.contentfulPage.section1.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section2 && 
      <TwoColumnContainer style={{ width: "80%" }}>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section2.sectionHeader}</H2>
            {data.contentfulPage.section2.sectionLink &&
            <Link to={data.contentfulPage.section2.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section2.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img style={{ margin: "auto", maxWidth: "200px" }} fluid={data.contentfulPage.section2.sectionImage.fluid} alt={data.contentfulPage.section2.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section3 && 
      <TwoColumnContainer style={{ width: "80%" }}>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section3.sectionHeader}</H2>
            {data.contentfulPage.section3.sectionLink &&
            <Link to={data.contentfulPage.section3.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section3.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img style={{ margin: "auto", maxWidth: "200px" }} fluid={data.contentfulPage.section3.sectionImage.fluid} alt={data.contentfulPage.section3.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section4 && 
      <TwoColumnContainer style={{ width: "80%" }}>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section4.sectionHeader}</H2>
            {data.contentfulPage.section4.sectionLink &&
            <Link to={data.contentfulPage.section4.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section4.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img style={{ margin: "auto", maxWidth: "200px" }} fluid={data.contentfulPage.section4.sectionImage.fluid} alt={data.contentfulPage.section4.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section5 && 
      <TwoColumnContainer style={{ width: "80%" }}>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section5.sectionHeader}</H2>
            {data.contentfulPage.section5.sectionLink &&
            <Link to={data.contentfulPage.section5.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section5.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img style={{ margin: "auto", maxWidth: "200px" }} fluid={data.contentfulPage.section5.sectionImage.fluid} alt={data.contentfulPage.section5.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section6 && 
      <TwoColumnContainer style={{ width: "80%" }}>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section6.sectionHeader}</H2>
            {data.contentfulPage.section6.sectionLink &&
            <Link to={data.contentfulPage.section6.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section6.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img style={{ margin: "auto", maxWidth: "200px" }} fluid={data.contentfulPage.section6.sectionImage.fluid} alt={data.contentfulPage.section6.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}
      
      {data.contentfulPage.section7 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section7.sectionHeader}</H2>
            {data.contentfulPage.section7.sectionLink &&
            <Link to={data.contentfulPage.section7.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section7.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section7.sectionImage.fluid} alt={data.contentfulPage.section7.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section8 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section8.sectionHeader}</H2>
            {data.contentfulPage.section8.sectionLink &&
            <Link to={data.contentfulPage.section8.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section8.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section8.sectionImage.fluid} alt={data.contentfulPage.section8.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section9 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section9.sectionHeader}</H2>
            {data.contentfulPage.section9.sectionLink &&
            <Link to={data.contentfulPage.section9.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section9.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section9.sectionImage.fluid} alt={data.contentfulPage.section9.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section10 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section10.sectionHeader}</H2>
            {data.contentfulPage.section10.sectionLink &&
            <Link to={data.contentfulPage.section10.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section10.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section10.sectionImage.fluid} alt={data.contentfulPage.section10.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query ServicesPageQuery {
    contentfulPage(pageName: {eq: "Services"}) {
      heroHeader
      heroDescription {
        heroDescription
      }
      heroImage {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      section1 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section2 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section3 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section4 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section5 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section6 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
    }
  }
`
